<template>
    <section>
        
        <hr>
            <div class="tipContentTips">
            <p class="help">获取内容出错，请进入其他页面，</p>
            <p class="help">或重新进入。</p>
            <p class="help">5秒后，自动进入首页</p>

        </div>
        <hr>
    </section>
</template>
<script>
export default {
    name:"M5Error",
    mounted(){
     
       
        setTimeout(() => {
             this.$router.push("/")
        }, 5000);
       
    },

}
</script>
<style>
    
</style>